export const buttons = [
    {
        id: 0,
        name: "Relatórios Financeiros"
    },
    {
        id: 1,
        name: "Registros de Movimentações Financeiras"
    },
    {
        id: 2,
        name: "Registro de Patrimônio"
    },
    {
        id: 3,
        name: "Registro de Ocorrências"
    }
]